import './App.css';
//import Footer from './components/Footer';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from './components/Home';
import CV from './components/CV';
import Contact from './components/Contact';
import {
    UserProvider
    //, useUser
} from './providers/UserProvider';
//import SignInOrOut from './components/Profile';
import SchmusiHome from './components/schmusi/SchmusiHome';
import SchmusisPurrfectPairsHome from './components/schmusi-s-purrfect-pairs/SchmusisPurrfectPairs';
import CharcoalHome from './components/charcoal/CharcoalHome';
import KaminoHome from './components/kamino/KaminoHome';
import Snapfit2Home from './components/snapfit2/Snapfit2Home';
import TeamTypeHome from './components/teamtype/TeamTypeHome';
import TestHome from './components/test/TestHome';

function App() {

//  const { user } = useUser();

  return (
    <div className="App">
      <head>
        <meta name="viewport" content="initial-scale=1, width=device-width" />
      </head>
      {/*<SignInOrOut />*/}
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/cv" element={<CV />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/apps/schmusi" element={<SchmusiHome />} />
          <Route path="/apps/charcoal" element={<CharcoalHome />} />
          <Route path="/apps/kamino" element={<KaminoHome />} />
          <Route path="/apps/snapfit2" element={<Snapfit2Home />} />
          <Route path="/apps/schmusi-s-purrfect-pairs" element={<SchmusisPurrfectPairsHome />} />
          <Route path="/apps/teamtype" element={<TeamTypeHome />} />
          <Route path="/apps/test" element={<TestHome />} />
        </Routes>
        {/*<Footer />*/}
      </Router>
    </div>
  )
}

function Root() {
  return (
    <UserProvider>
      <App />
    </UserProvider>
  );
}

export default Root;
